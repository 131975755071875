import React, {useEffect, useRef} from "react";
import { graphql, Link } from "gatsby";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types'

import Layout from "../components/Layout";
import { useIntl, changeLocale } from "gatsby-plugin-intl";

import Seo from "../components/seo";

// We got the $slug variable from the gatsby-node.js file because we pass the value in the 
// createPage in the third parameter context.

export const query = graphql`
query ($slug: String!) {
    contentfulService(slug: {eq: $slug}) {
      title
      subtitle
      body {
        raw
      }
      bodyFa {
        raw
      }
      bodyFr {
        raw
      }
      bodyHy {
        raw
      }
      bodyRu {
        raw
      }
    }
  }  
`
// We got the data from the query above
const ServicePageTemplate = ({ data }) => {

    const intl = useIntl();

    const stylingName = (name) =>{  
      let finalClass = name;
      const rtl = intl.locale !== "fa" ? false : true ;
      if (rtl) {
        finalClass = `${name}RTL` ; 
      } 
      return finalClass; 
    }
    function addHyFont() {
      if(document !== "undefined"){ //stop the build from failing 
        if(document.querySelector(".content-body")){
          const content = document.querySelector(".content-body");
          content.classList.add("HyFont");
        }
      }
    }
  
    function addFaFont() {
      if(document !== "undefined"){ //stop the build from failing 
        if(document.querySelector(".content-body")){
          const content = document.querySelector(".content-body");
          content.classList.add("FaFont");
        }
      }
    }
  
    function addLatinFont() {
      if(document !== "undefined"){ //stop the build from failing
        // First removing possible Hy and Fa Font:
        if(document.querySelector(".HyFont")){
          const content = document.querySelector(".HyFont");
          content.classList.remove("HyFont");
        }
        if(document.querySelector(".FaFont")){
          const content = document.querySelector(".FaFont");
          content.classList.remove("FaFont");
        }
        // Now adding the Latin font: 
        if(document.querySelector(".content-body")){
          const content = document.querySelector(".content-body");
          content.classList.add("LatinFont");
        }
      }
    }

    const options = {
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data
          if (uri.startsWith("http") || uri.startsWith("https") ){
            return(
              <a 
                href={uri.trim()}
                >
                {children}
              </a>
            )
          }else{
            return (
              <Link 
                to={uri.trim()}
                state={{prevUri: uri.trim()}}
              >
                {children}
              </Link>
            )
          }
        },
      },
    }
    
    let content = useRef(data.contentfulService.body); 

    useEffect(()=>{
        if(intl.locale !=="en"){
            changeLocale(intl.locale);
        }
        switch(intl.locale){
            case "hy":
                //adding Hy Font:
                addHyFont();
                content.current = data.contentfulService.bodyHy;
                break;
            case "ru":
                // removing possible Hy and Fa fonts and add Latin font:
                addLatinFont();
                content.current = data.contentfulService.bodyRu; 
                break; 
            case "fa": 
                //adding Fa Font:
                addFaFont();
                content.current = data.contentfulService.bodyFa; 
                break; 
            case "fr":
                // removing possible Hy and Fa fonts and add Latin font:
                addLatinFont();
                content.current = data.contentfulService.bodyFr;
                break;
            default:
                // removing possible Hy and Fa fonts and add Latin font:
                addLatinFont();
                content.current = data.contentfulService.body; 
                break; 
        }
    },[
        intl.locale, 
        data.contentfulService.body,
        data.contentfulService.bodyHy,
        data.contentfulService.bodyRu,
        data.contentfulService.bodyFa,
        data.contentfulService.bodyFr,
    ])
  
    return (
    <Layout isHome={false}>
        <div className={stylingName("container")}>
            {!data ? (
            <h1>Something went wrong, please try again</h1>
            ):(
            <> 
            <article className={stylingName("content")}>
                <h1 className="content-title">
                    {intl.formatMessage({id: `${data.contentfulService.title}` })}
                </h1>
                <section className="content-body">
                  <h2 className={stylingName("content-body")}>
                      {intl.formatMessage({id: `${data.contentfulService.subtitle}`})}
                  </h2>
                  <div>
                    {renderRichText(content.current, options)}
                  </div>
                </section>
            </article> 
            </>
            )}
        </div>
    </Layout>
  )
}

export const Head = ({ data, pageContext }) => (
  <Seo
  title={`${pageContext.intl.messages[`${data.contentfulService.title}`]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
  description={pageContext.intl.messages[`${data.contentfulService.subtitle}`]}
  article={false}>
  </Seo>
)   

export default ServicePageTemplate